import { render, staticRenderFns } from "./storeJoin.vue?vue&type=template&id=57568c57&scoped=true&"
import script from "./storeJoin.vue?vue&type=script&lang=js&"
export * from "./storeJoin.vue?vue&type=script&lang=js&"
import style0 from "./storeJoin.vue?vue&type=style&index=0&id=57568c57&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57568c57",
  null
  
)

export default component.exports